.layout2 {
  .topbar {
    position: relative;
    width: 100%;
    display: table;
    height: $topbar-height;
    border-bottom: 1px solid transparent;
    padding-top: 1rem;
    padding-bottom: 1rem;
    z-index: 98;
    .brand {
      height: 100%;
      .brand__text {
        font-weight: 500;
        font-size: 1.5rem;
        margin: 0 1rem;
      }
    }

    .MuiIconButton-root {
      color: $white;
    }

    .company-logo {
      width: 292px;
      height: 58px;
      padding-left: 6px;
    }
  }
}