.border-radius-0 {
  border-radius: 0px !important;
  overflow: hidden;
}
.border-radius-4 {
  border-radius: 4px !important;
  overflow: hidden;
}
.border-radius-8 {
  border-radius: 8px !important;
  overflow: hidden;
}
.border-radius-circle {
  border-radius: 50% !important;
  overflow: hidden;
}
.border-none {
  border: none !important;
}
