body {
  user-select: none;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  overflow: hidden;
  position: relative; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  color: rgba(255, 0, 221, 0.863);
  font-size: 16px; }

.component-contain {
  margin: 0 30px 0;
  padding: 10px; }

.component-breadcrumb {
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ccc; }

.component-card__search {
  margin-bottom: 16px; }
