.chat-sidenav {
  border-right: 1px solid $light-gray;
  height: 450px;
  .chat-contact-list {
    height: 100%;
  }
}
.chat-container {
  background: rgba(0, 0, 0, 0.05);
  height: 450px;
  .chat-message-list {
    .list__message {
      border-radius: 4px;
      overflow: hidden;
    }
  }
  .empty-message-circle {
    height: 220px;
    width: 220px;
    border-radius: 50%;
    box-shadow: $elevation-z6;

    .MuiIcon-root {
      font-size: 4rem !important;
    }
  }
}
