.egret-search-box {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 9;
  .search-box {
    outline: none;
    border: none;
    font-size: 1rem;
    height: calc(100% - 5px);
  }
}
