.mini-cart {
  width: $sidenav-width;
  .cart__topbar {
    height: $topbar-height;
    box-shadow: $elevation-z6;
  }
  .mini-cart__item {
    transition: background 300ms ease;
    &:hover {
      background: $light-gray;
    }
    img {
      width: 80px;
    }
  }
}
